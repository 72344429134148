<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="table-header m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mr-1 mb-0">Show</label>
            <b-form-select
              v-model="perPage"
              :options="perPageOptions"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <label class="mr-1 mb-0">Search</label>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                class="w-25"
              />
              <b-button
                class="ml-1"
                variant="primary"
                :to="{ name: 'admin-report-details' }"
              >
                <span class="mr-25 align-middle text-light">Create Report</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refReportsListTable"
        :items="fetchReportsList"
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <!-- Loader -->
        <template #table-busy>
          <TableSpinner />
        </template>

        <!-- Column: name -->
        <template #cell(name)="data">
          <b-link
            :to="{ name: 'admin-report-details', query: { id: data.item.id } }"
          >{{ data.item.name }}</b-link>
        </template>

        <!-- Column: report type -->
        <template
          #cell(reportView)="data"
        >
          <span v-if="data.item.reportView">{{ data.item.reportView.name }}</span>
        </template>

        <!-- Column: created by -->
        <template #cell(created_by)="data">
          <span v-if="data.item.user_id">{{ data.item.user_id.full_name }}</span>
        </template>

        <!-- Column: created at -->
        <template #cell(created_at)="data">
          {{ data.item.created_at | formatDate }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="mx-auto p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              :to="{ name: 'admin-report-details', query: { id: data.item.id } }"
            >
              <span>Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="deleteReport(data.item.id)"
            >
              <span>Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>

  </div>

</template>

<script>
import moment from 'moment'
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination,
  BDropdown, BDropdownItem, BFormSelect, BButton, BLink,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import { downloadExportFile } from '@core/mixins/downloadExportFile'

import store from '@/store'
import storeModule from '@/views/admin/reports-v2/reportStoreModule'
import useReportsList from '@/views/admin/reports-v2/reports/reports-list/useReportsList'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BButton,
    BLink,

    TableSpinner,
  },
  filters: {
    formatDate(value) {
      return value ? moment(value).format('YYYY-MM-DD') : ''
    },
  },
  mixins: [downloadExportFile],
  data() {
    return {
      isLoading: false,
      selectedReportName: null,
    }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-reports'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchReportsList,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      totalItems,
      isSortDirDesc,
      refReportsListTable,

      statusFilter,

      deleteReport,

      refetchData,
    } = useReportsList()

    return {
      fetchReportsList,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      totalItems,
      isSortDirDesc,
      refReportsListTable,

      statusFilter,

      deleteReport,

      refetchData,
    }
  },
  computed: {
    csvName() {
      return `${this.selectedReportName}-report-${moment().format('YYYY-MM-DD')}`
    },
  },
  methods: {
    resetFile() {
      this.csvFile = null
    },
    async exportReportCSV(report) {
      this.isLoading = true
      this.selectedReportName = report.name

      const queryParams = {
        report_view_id: report.reportView.id,
        selected_fields: report.selected_fields,
        filters: report.filters,
      }

      await this.exportReport(queryParams)
        .then(response => {
          this.isLoading = false
          this.csvFile = response.data
        })
    },
    saveCSV() {
      this.downloadFile(this.csvFile, this.csvName, 'csv')
    },
    async exportStudentsApplicationsToCsv() {
      this.isStudentsExporting = true
      await this.exportStudentsApplications().then(response => {
        this.downloadFile(response.data, `students+applications-${moment().format('YYYY-MM-DD')}`, 'csv')
        this.isStudentsExporting = false
      })
    },
    async exportParentsTransactionsToCsv() {
      this.isParentsExporting = true
      await this.exportParentsTransactions().then(response => {
        this.downloadFile(response.data, `parents+transactions-${moment().format('YYYY-MM-DD')}`, 'csv')
        this.isParentsExporting = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.table-header {

  label {
    margin-bottom: 0;
  }
}

.badge {
  text-transform: uppercase;
}

.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
