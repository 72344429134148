import { ref, watch, computed } from '@vue/composition-api'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default function useGroupMembers() {
  // Use toast
  const toast = useToast()

  const refReportsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'Report Name', sortable: false },
    { key: 'reportView', label: 'Report View', sortable: false },
    { key: 'created_by', label: 'Created By', sortable: false },
    { key: 'created_at', label: 'Created At', sortable: false },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refReportsListTable.value ? refReportsListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    refReportsListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchReportsList = (ctx, callback) => {
    const payloadData = {
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      is_default_report: true,
    }

    if (searchQuery.value) {
      payloadData.name = searchQuery.value
    }

    store
      .dispatch('app-reports/fetchReportsList', payloadData)
      .then(response => {
        const { data } = response.data
        const { total } = response.data.meta

        callback(data)
        totalItems.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching reports list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteReport = ID => axios
    .delete(`auth/reports/${ID}`, { params: { is_default_report: true } })
    .then(() => {
      refetchData()
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success deleting report',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error deleting report',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  return {
    fetchReportsList,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    totalItems,
    isSortDirDesc,
    refReportsListTable,

    statusFilter,

    deleteReport,

    refetchData,
  }
}
